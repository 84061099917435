import React,{FunctionComponent} from "react";
import OpenPositions from "../../components/careers/open-positions";

const FindOpenPositions:FunctionComponent = ()=> {
    return(
    <>
    <OpenPositions></OpenPositions>
    </>)
}

export default FindOpenPositions;