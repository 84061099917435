import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
// import { readFile } from "fs";

const ApplyForm: FunctionComponent<{ applyingFor: string }> = ({ applyingFor }) => {
    const [apiStatus, setApiStatus] = useState(false);
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const onCloseAlert = React.useCallback(() => {
        setIsAlertVisible(false);
    }, []);

    /* Encoding file to base64 */
    const toBase64 = file => {
        return new Promise((resolve, reject) => {
            let baseURL;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                //console.log("Called", reader);
                baseURL = reader.result;
                // console.log(baseURL);
                resolve(baseURL);
            }

            reader.onerror = error => reject(error);
        })
    };




    const sendEmail = async () => {
       // const file = await toBase64(formik.values.file)
        // console.log("File is", file);
        const emailDetails = {
            personalizations: [
                {
                    to: [
                        {
                            email: process.env.CONTACT_US_TO_EMAIL
                        }
                    ],
                    subject: "Job application from - " + formik.values.email
                }
            ],
            from: {
                name: process.env.CONTACT_US_FROM,
                email: process.env.CONTACT_US_FROM_EMAIL
            },
            content: [
                {
                    type: "text/html",
                    value: "Full Name : " + formik.values.fullname +
                        "<br> email : " + formik.values.email +
                        "<br>" + applyingFor +
                        "<br> Current Job : " + formik.values.currentjob +
                        "<br> Phone/Mobile : " + formik.values.phonenumber +
                        "<br> Skill Area : " + formik.values.skillarea +
                        "<br> Message : " + formik.values.message
                }
            ],
            // attachments: [
            //     {
            //         // content: formik.values.file,
            //         // type: SUPPORTED_FORMATS,
            //         // disposition: "attachment",
            //         //   contentId: filename,
            //     },
            // ]
        };



        //console.log(emailDetails);





        axios.post(process.env.CONTACT_US_FUNCTION_API || '', emailDetails, )
            .then((response) => {
                //console.log(response);
            }, (error) => {
                //console.log(error);
            });

    }


    const FILE_SIZE = 5000000;
    const SUPPORTED_FORMATS = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
    const formik = useFormik({
        initialValues: {
            fullname: "",
            email: "",
            currentjob: "",
            phonenumber: "",
            skillarea: "",
            message: "",
            file: undefined,
            agree: true,
        },
        validateOnBlur: true,

        validationSchema: Yup.object().shape({
            fullname: Yup.string().required("Full Name required."),
            email: Yup.string()
                .email("invalid Email address.")
                .required("Email required."),
            currentjob: Yup.string().required("Current Job is required"),
            phonenumber: Yup.string().required("Phone/Mobile is required").matches(
                /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                { message: "incorrect phone number format." }
            ),
            skillarea: Yup.string().required("Skill Area is required"),
            message: Yup.string().required("Message is required"),
            file: Yup.mixed()
                .required("Resume is required")
                .test("FILE_SIZE", "Uploaded file is too big.",
                    value => !value || (value && value.size <= FILE_SIZE))
                .test("FILE_FORMAT", "Uploaded file has unsupported format.",
                    value => !value || (value && SUPPORTED_FORMATS.includes(value.type))),
            agree: Yup.bool().oneOf([true], 'Accept Terms & Conditions is required')

        }),



        onSubmit: (values) => {
            if (formik.isValid && formik.values.agree) {
                // console.log(values);
                // console.log("valid");
                sendEmail();
                setApiStatus(true);
                setIsAlertVisible(true);
                formik.resetForm();
            }
        },
    });

    return <form onSubmit={formik.handleSubmit} autoComplete="off" >
        <div className=" flex flex-col h-full text-sm space-y-2 px-3 md:items-center">
            <div className=" md:w-1/2 sm:w-full ">
                <input type="text" maxLength={100} id="fullname" className="w-full rounded-md p-2 my-2 border border-gray-400 " placeholder="Full Name *" value={formik.values.fullname} onChange={formik.handleChange} />
                {formik.touched.fullname && formik.errors.fullname ? <ErrorMessage message={formik.errors.fullname} /> : null}
            </div>
            <div className="md:w-1/2 sm:w-full">
                <input type="email" maxLength={100} id="email" className="w-full rounded-md  my-2 border border-gray-400 p-2  " placeholder="Email Address *" value={formik.values.email} onChange={formik.handleChange} />
                {formik.touched.email && formik.errors.email ? <ErrorMessage message={formik.errors.email} /> : null}
            </div>
            <div className="md:w-1/2 sm:w-full">
                <input type="text" maxLength={100} id="currentjob" className="w-full rounded-md  my-2 border border-gray-400 p-2" placeholder="Current Job * " value={formik.values.currentjob} onChange={formik.handleChange} />
                {formik.touched.currentjob && formik.errors.currentjob ? <ErrorMessage message={formik.errors.currentjob} /> : null}
            </div>
            <div className="md:w-1/2 sm:w-full" >
                <input type="text" maxLength={18} id="phonenumber" className="w-full rounded-md  my-2 border border-gray-400 p-2" placeholder="Phone/Mobile * " value={formik.values.phonenumber} onChange={formik.handleChange} />
                {formik.touched.phonenumber && formik.errors.phonenumber ? <ErrorMessage message={formik.errors.phonenumber} /> : null}
            </div>
            <div className="md:w-1/2 sm:w-full">
                <input type="text" maxLength={100} id="skillarea" className="w-full rounded-md  my-2 border border-gray-400 p-2" placeholder="Skill Area * " value={formik.values.skillarea} onChange={formik.handleChange} />
                {formik.touched.skillarea && formik.errors.skillarea ? <ErrorMessage message={formik.errors.skillarea} /> : null}
            </div>


            {/* <div></div> */}
            <div className="flex flex-col md:w-1/2 sm:w-full">
                <textarea id="message" maxLength={500} className="rounded-md my-2 border border-gray-400 p-2 w-full " placeholder="Message *" rows={4} cols={50} value={formik.values.message} onChange={formik.handleChange} />
                {formik.touched.message && formik.errors.message ? <ErrorMessage message={formik.errors.message} /> : null}
            </div>
            <div className="flex flex-col  rounded-lg w-full p-4 border border-gray-400 sm:h-32 md:w-1/2 md:h-36 lg:h-28 ">
                <div className="my-2">
                    <label className="font-medium rounded-full bg-bytestrone-red text-white h-10 px-5 py-3 uppercase text-large cursor-pointer transform hover:bg-bytestrone-green" > upload resume
                        <input type="file" className="hidden" accept={`${SUPPORTED_FORMATS}`} onChange={(event) => {
                            // console.log(event.target.files?.[0]);

                            formik.setFieldValue("file", event.target.files?.[0])
                        }} />
                    </label>
                </div>
                <div className="mt-1 ">
                    <h6 className="uppercase text-bytestrone-blue md:text-sm">
                        only .pdf, .doc, .docx file types are allowed. max size 5mb
                    </h6>
                </div>
                <div className="">

                    {formik.touched.file && formik.errors.file ? <ErrorMessage message={formik.errors.file} /> : null}
                </div>
            </div>
            <div className="grid grid-rows-2 grid-flow-col gap-y-1">
                <div className="grid grid-rows-1 grid-flow-col gap-x-1">
                    <input type="checkbox" name="agree"
                        defaultChecked
                        className="text-gray-100"
                        onChange={formik.handleChange}
                        onClick={() => {
                            formik.values.agree = !formik.values.agree;
                            //console.log(formik.values.agree);
                        }}
                    />
                    <label className="font-bytestrone">
                        I have read Bytestrone's  <a href="/privacy-policy" target="_blank" className="text-bytestrone-blue font-semibold"> Privacy Policy</a> and agree to <a href="/terms-of-service" target="_blank" className="text-bytestrone-blue font-semibold">Terms and Conditions.</a>

                    </label>
                    {/* <a href="https://www.codexworld.com" target="_blank">Visit CodexWorld</a> */}
                </div>
                <div>
                    {formik.touched.agree && formik.errors.agree ? <ErrorMessage message={formik.errors.agree} /> : null}
                </div>
            </div>
            <div className="flex justify-center">
                <input type="submit" disabled={false} className=" mt-2 bg-bytestrone-red text-white uppercase cursor-pointer  py-2 px-5 text-base transform hover:bg-bytestrone-green md:px-14 rounded-full disabled:opacity-50 " />
            </div>
            {isAlertVisible ? <Alert message={apiStatus ? "One of our colleagues will get back in touch with you soon!" : "Error creating request, please try after some time."} isSuccess={apiStatus} onClose={onCloseAlert} /> : null}
        </div>
    </form>
}



const ErrorMessage: FunctionComponent<{ message: string }> = ({ message }) => {
    return <div style={{ marginTop: '0px', marginBottom: '0px' }} className="text-red-400 text-sm">{message}</div>
}

const Alert: FunctionComponent<{ isSuccess: boolean, message: string, onClose?: Function }> = ({ isSuccess, message, onClose }) => {
    const className = isSuccess ? 'border-green-400 text-green-700' : "border-red-400 text-red-700";
    const alertContainer = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const timeout = setTimeout(() => {
            if (onClose && alertContainer) {
                alertContainer.current?.classList.add("opacity-0");
                onClose();
            }
        }, 6000);

        return () => {
            return clearTimeout(timeout);
        }
    }, []);

    return (
        <div ref={alertContainer} className={`bg-white border  px-3 py-3 rounded relative ${className} transition-opacity duration-1000 ease-in-out sm:w-full md:w-1/2`} role="alert">
            <strong className="font-bold">Thank you for getting in touch! </strong>
            <span className="block sm:inline">{message}</span>
            <button className="absolute top-0 bottom-0 right-0 px-1 py-3 focus:outline-none" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (onClose) {
                    onClose();
                }
            }}>
                <svg className="fill-current h-6 w-6 text-gray-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
            </button>
        </div>
    );
}


export default ApplyForm;