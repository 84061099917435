import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import Section from "../shared/section/sectionWrapper";
import Text from "../shared/text";
import { graphql, useStaticQuery, navigate } from "gatsby";

import * as Yup from "yup";
import { useFormik } from "formik";
import ApplyForm from "./form";


interface findInterface {
  findJob
  clear
  clearActive
}

interface ListPositionsInterface {
  data: any
}

const jobsQuery = graphql`
  query JobsQuery {
    allStrapiJobs{
      edges{
        node{
          jobID
          jobTitle
          jobSubTitile
          location
          stateAndCountry
        }
      }
    }
  }`;





const OpenPositions: FunctionComponent = () => {
  const { allStrapiJobs } = useStaticQuery(jobsQuery);
  const [findActive, setFindActive] = useState(false);
  const [jobData, setJobData] = useState(allStrapiJobs.edges);
  const [filteredjobData, setFilteredJobData] = useState();



  const findJob = (data: { location: string; skillArea: string; keyword: string; }) => {

    const job = jobData.filter(item => (item.node.location.toLowerCase() == data.location.toLowerCase() || data.location == '') &&
      (item.node.jobSubTitile.toLowerCase() == data.skillArea.toLowerCase() || data.skillArea == '') &&
      (item.node.jobTitle.toLowerCase().includes(data.keyword.toLowerCase().trim()) || data.keyword.toLowerCase() == ''))
    setFilteredJobData(job);
    setFindActive(true);
  }

  const clearFind = () => {
    setFindActive(false);
  }





  return (
    <div>
      <Section className="bg-bytestrone-blue pr-10 ">
        <div
          id="open-positions"
          className="flex flex-col mt-5 w-full h-full  md:py-10 py-20 space-y-4"
        >
          <Text
            className="text-center text-white lg:text-right whitespace-nowrap"
            textType="headerL"
          >
            Open Positions
          </Text>
          <Find findJob={findJob} clear={clearFind} clearActive={findActive} />

          <div className="flex  h-full  justify-start gap-9 pl-10  pt-10 flex-col md:flex-row flex-wrap ">
            {findActive ? <ListPositions data={filteredjobData} /> : <ListPositions data={jobData} />}
            {/* <ListPositions data={jobData} />*/}
          </div>


        </div>
      </Section>
      <Section className="bg-white">
        <div id="precision-team" className="pb-5"></div>
        <div className="flex flex-col w-full h-full p-5  py-10 space-y-5">
          <Text
            className="text-center text-bytestrone-blue lg:text-left whitespace-nowrap"
            textType="headerL"
          >
            Leave A Message
          </Text>
          <div className="">
            <div className="flex flex-col w-full h-full p-5  py-10 space-y-5">
              <Text textType="contentXl">
                Leave us a message and we will reach out to
                you, maybe we might have something interesting for you.
              </Text>
            </div>
            {/* <div className="flex flex-row  flex-wrap flex-grow"> */}
            <ApplyForm applyingFor={`Leaving a message for you `} ></ApplyForm>
          </div>
        </div>
      </Section>

    </div>
  );
};




const Find: FunctionComponent<findInterface> = ({ findJob, clear, clearActive }) => {
  const form = useRef<HTMLFormElement>(null)
  const filterSearch = useFormik({
    initialValues: {
      location: "",
      skillArea: "",
      keyword: ""
    },

    onSubmit: (values) => {
      findJob(values);
    }
  });

  const clearFind = () => {
    clear();
    form.current?.reset();
    filterSearch.values.location = '';
    filterSearch.values.skillArea = '';
    filterSearch.values.keyword = '';


  }

  return (
    <div className=" flex flex-col">
      {/*flex  h-full items-start justify-start  flex-col md:flex-row flex-col flex-wrap*/}
      <div className="  text-white pb-1 px-10">
        <Text textType="contentXl" >
          Here's what we have right now.
        </Text>

      </div>

      <div className="flex  pt-5 " >
        <form onSubmit={filterSearch.handleSubmit} ref={form} className="w-full" autoComplete="off" >
          <div className="flex w-full justify-center pl-10 flex-col  ">

            <div className="flex flex-col gap-y-4 md:flex-row md:flex-flow-col  md:w-full  md:h-9 justify-start  ">

              <div className="flex md:pr-4 md:w-96 h-9 md:h-auto ">
                <select id="location" className="w-full lg:pl-2 rounded-md " onChange={filterSearch.handleChange} defaultValue={'kochi'}>
                  <option value="" >Location</option>
                  <option value="kochi">KOCHI</option>
                </select>
              </div>

              <div className="flex md:px-4 md:w-96 h-9 md:h-auto">
                <select id="skillArea" className="w-full lg:pl-2 rounded-md" onChange={filterSearch.handleChange} defaultValue={'skill area'} >
                  <option value="skill area" >Skill Area</option>
                  <option value="Automation">Automation</option>
                  <option value="GCP">GCP</option>
                  <option value="Java Full Stack">Java Full Stack</option>
                </select>
              </div>
              <div className="flex md:px-4 md:w-96 h-9 md:h-auto">
                <input type="text" className="w-full rounded-md pl-2 " id="keyword" placeholder="Keyword" onChange={filterSearch.handleChange} />
              </div>
              <div className="flex sm:pl-0 md:px-3  ">
                <button type="submit" className="flex sm:pl-0 sm:pr-3 md:px-4 w-32 h-9 bg-bytestrone-red text-white justify-center items-center rounded-full">FIND</button>

                <div className="px-4">

                  {
                    clearActive ?
                      <button onClick={clearFind} className="flex px-4 w-32 h-9  bg-bytestrone-red text-white  items-center  rounded-full justify-center">CLEAR</button>
                      :
                      null
                  }
                </div>
              </div>
            </div>
          </div>
        </form>

      </div>
    </div >
  );
}

const ListPositions: FunctionComponent<ListPositionsInterface> = ({ data }) => {
  if (data.length === 0) {
    return (
      <div className="text-white justify-center px-5 py-10 font-bold"> <Text textType="header">No Results Found</Text> </div>
    )
  }
  else {
    return (

      data.map((item, index) => {
        return <div className="flex  flex-col-3 w-full   bg-white rounded-lg md:h-52  md:w-3/12 md:max-h-fit lg:h-52 lg:w-3/12 lg:max-h-fit xl:h-48 xl:max-h-min xl:w-3/12 2xl:h-44 2xl:max-h-fit shadow-lg" key={index}>

          <div className="flex flex-col w-full ">
            <div className="w-full flex-row px-3 max-h-max	 md:mt-2 md:h-16">
              <Text textType="contentXl" className="text-bytestrone-blue capitalize"><b>{item.node.jobTitle}</b></Text>
            </div>
            <div className="w-full grid grid-cols-2  ">
              <div className=" shrink-0  min-h-max	min-w-max	 bg-bytestrone-blue mt-3 rounded-r-full px-3 md:mt-2"><Text textType="content" className="text-white">{item.node.jobSubTitile}</Text></div>
            </div >

            <div className="w-full grid grid-rows-2 grid-flow-cols px-3 my-1 xl:my-0  md:min:h-max lg:self-center">
              <h6 className="font-bytestrone font-semibold text-xs md:text-sm lg:text-base uppercase">{item.node.location}</h6>
              {/* <Text textType="contentSm" className="uppercase flex"><b>{item.node.location}</b></Text> */}
              <div className="grid content-center mt-0  grid-cols-2 md:content-center h-1/2 space-x-1 md:space-x-0 lg:space-x-0	">
                {/* <Text textType="contentSm" className="uppercase flex w-10 h-8"><b>{item.node.stateAndCountry}</b></Text> */}
                <div className="flex">
                  <h6 className="font-bytestrone font-semibold text-xs md:text-sm lg:text-base uppercase h-8 ">{item.node.stateAndCountry}</h6>
                </div>
                {/* <Link to="/apply"><div className="bg-bytestrone-green text-white justify-center  rounded w-1/2 md:p-3 md:text-base  font-medium rounded-full w-3/4 focus:outline-none uppercase"> View </div> </Link> */}

                <div className="flex lg:justify-center ">
                  <button type="button" className="flex-none bg-bytestrone-green text-white w-full text-xs md:text-sm py-0.5 md:px-2  md:py-1 lg:text-base   font-medium rounded-full lg:w-3/4 focus:outline-none uppercase" onClick={() => navigate(`/careers/apply/${item.node.jobID}`)}>Apply</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      })

    )
  }
}

export default OpenPositions;


